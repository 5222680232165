var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timeclock-card" }, [
    _c("div", { staticClass: "timeclock-card__main" }, [
      _c(
        "div",
        { staticClass: "flex bet a-center" },
        [
          _c("div", [
            _c("div", {
              domProps: {
                textContent: _vm._s(
                  _vm.isBreak ? _vm.$t("Break") : _vm.$t("Start")
                ),
              },
            }),
            _vm.isBreak
              ? _c("div", {
                  staticClass: "grey fs14",
                  domProps: {
                    textContent: _vm._s(
                      _vm.filters.date(_vm.timeclock.break.clock, "DD/MM/YYYY")
                    ),
                  },
                })
              : _c("div", {
                  staticClass: "grey fs14",
                  domProps: {
                    textContent: _vm._s(
                      _vm.timeclock.start
                        ? _vm.timeclock.start.clock
                        : _vm.filters.date(
                            _vm.timeclock.end.clock,
                            "DD/MM/YYYY"
                          )
                    ),
                  },
                }),
          ]),
          _c("edit-btn", { on: { click: _vm.data.click } }),
        ],
        1
      ),
      _vm.isBreak
        ? _c("div", { staticClass: "timeclock-card__main_main" }, [
            _vm.timeclock.break.clock
              ? _c("div", { staticClass: "timeclock-card__main_name" }, [
                  _c("div", {
                    staticClass: "grey",
                    domProps: { textContent: _vm._s(_vm.$t("Start")) },
                  }),
                  _c("div", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.filters.time(_vm.timeclock.break.clock)
                      ),
                    },
                  }),
                ])
              : _c("div"),
            _vm.timeclock.break.clock_end
              ? _c("div", { staticClass: "timeclock-card__main_name" }, [
                  _c("div", {
                    staticClass: "grey",
                    domProps: { textContent: _vm._s(_vm.$t("End")) },
                  }),
                  _c("div", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.filters.time(_vm.timeclock.break.clock_end)
                      ),
                    },
                  }),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "timeclock-card__main_main" }, [
            _vm.timeclock.start
              ? _c("div", { staticClass: "timeclock-card__main_name" }, [
                  _c("div", {
                    staticClass: "grey",
                    domProps: { textContent: _vm._s(_vm.$t("Start")) },
                  }),
                  _c("div", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.filters.time(_vm.timeclock.start.clock)
                      ),
                    },
                  }),
                ])
              : _c("div"),
            _vm.timeclock.end
              ? _c("div", { staticClass: "timeclock-card__main_name" }, [
                  _c("div", {
                    staticClass: "grey",
                    domProps: { textContent: _vm._s(_vm.$t("End")) },
                  }),
                  _c("div", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.filters.time(_vm.timeclock.end.clock)
                      ),
                    },
                  }),
                ])
              : _vm._e(),
          ]),
    ]),
    _vm.total
      ? _c("div", { staticClass: "timeclock-card__btm" }, [
          _c("div", {
            staticClass: "grey",
            domProps: { textContent: _vm._s(_vm.$t("Total")) },
          }),
          _c("div", { domProps: { textContent: _vm._s(_vm.total) } }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }